<template>
  <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="quit-container common-padding">
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <div class="common-screen-container">
      <div class="common-input-container">
        <span>离职员工姓名:</span>
        <el-input
          v-model="params.name"
          class="common-screen-input"
          placeholder="请输入"
        ></el-input>
      </div>
      <!-- <div v-if="!$vuex.state.onlyCompany" class="common-input-container">
        <span>所属公司:</span>
        <el-select
          class="common-screen-input"
          v-model="params.companyId"
          placeholder="请选择"
          @change="companyFun"
          filterable
        >
          <el-option
            v-for="item in rolesCompany"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div> -->
      <el-button class="common-screen-btn" type="primary" @click="() => companyFun()"
        >查 询</el-button
      >
      <el-button class="common-screen-btn" @click="() => resetBtn()" plain
        >重 置</el-button
      >
    </div>
    <el-table class="common-table" :data="tableData" :fit="true">
      <el-table-column
        prop="nick_name"
        label="人员姓名"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="quit_reason"
        label="离职原因"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="note"
        label="离职备注"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="quit_time"
        label="离职时间"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="create_name"
        label="操作人"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="create_time"
        label="操作时间"
        :formatter="tableColumn"
        width="200px"
      ></el-table-column>
      <el-table-column
        prop="role_name"
        label="角色"
        :formatter="tableColumn"
      ></el-table-column>
      <!-- <el-table-column prop="no" label="标签" :formatter="tableColumn"> </el-table-column> -->
      <el-table-column
        prop="depart_name"
        label="部门"
        :formatter="tableColumn"
      ></el-table-column>
    </el-table>
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.pageNum"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
  </div>
</template>
<script>
import { quitList } from "../../service/manage.js";
import { Config, tableColumn } from "../../utils/index.js";
import Breadcrumb from "../../components/common/breadcrumb.vue";
import { mapState } from "vuex";

export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      tableColumn,
      loading: false,
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "组织", isLink: false },
        { title: "离职员工", isLink: false },
      ],
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100],
      },
      params: {
        pageSize: 10,
        pageNum: 1,
        name: "",
      },
      tableData: [],
      rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company, // 所属公司下拉
    };
  },
  created() {
    this.getComId()

    // this.quitList();
  },
  computed: {
    ...mapState(["getUserInfo","comId"])
  },
  methods: {
    // 获取comID&&请求列表
    getComId() {
      setTimeout(()=>{
        if(this.comId){
          this.params.companyId = this.comId
        }else{
          this.params.companyId = this.getUserInfo.roles_company.filter((item) => { return item.id != 0; })[0].id
        }
        this.quitList();
      },400)
    },
    async quitList() {
      this.loading = true;
      let params = { ...this.params };
      let resData = (await quitList(params)).data;
      this.pagination.total = resData.total;
      this.pagination.pageSize = resData.size;
      this.tableData = resData.records;
      this.loading = false;
    },
    companyFun() {
      this.params.pageNum = 1;
      this.params.pageSize = 10;
      this.quitList();
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.pageNum = e;
      }
      this.quitList();
    },
    resetBtn() {
      this.params = {
        pageNum: 1,
        pageSize: 10,
        name: "",
        companyId: this.comId
      };
      this.quitList();
    },
  },
};
</script>
<style lang="less" scoped>
.quit-container {
}
</style>
